import { React, useState, useEffect } from "react";
import LocomotiveScroll from "locomotive-scroll";
import "./Landing.css";
import Card from "./Components/Card.js";
import Immerser from "immerser";



import img1_1 from "./images/1_1.jpg";
import vid1_2 from "./images/1.2.webm";
import img1_3 from "./images/1_3.jpg";
import vid2_1 from "./images/2.1.webm";
import img2_2 from "./images/2_2.jpg";
import img2_3 from "./images/2_3.jpg";
import img3_1 from "./images/3_1.jpeg";
import vid3_2 from "./images/3.2.webm";
import vid3_3 from "./images/3.3.webm";
import vid4_1 from "./images/4.1.webm";
import img4_2 from "./images/4_2.jpg";
import img4_3 from "./images/4_3.jpg";
import vid5_1 from "./images/5.1.webm";
import img5_2 from "./images/5_2.jpg";
import vid5_3 from "./images/5.3.webm";
import vid6_1 from "./images/6.1.webm";
import vid6_2 from "./images/6.2.webm";
import img6_3 from "./images/6_3.jpg";
import img7_1 from "./images/7_1.jpg";
import vid7_2 from "./images/7.2.webm";
import vid7_3 from "./images/7.3.webm";
import vid8_1 from "./images/8.1.webm";
import img8_2 from "./images/8_2.jpg";
import vid8_3 from "./images/8.3.webm";


function Landing() {
  // image --> True / video --> False
  let data = [
    {
      sizes: ["large", "small", "medium"],
      images: [img1_1, [vid1_2, "100%","100%"], img1_3],
      type: [true, false, true],
      title: [
        "NAJLA GARCI MAKEUP ARTIST",
        "MIT & APII",
        "EVASION READY-TO-WEAR",
      ],
      project_pages: ["/Najla-Garci", "/MIT", "/Evasion"],
      title_id: ["", "", ""],
    },
    {
      sizes: ["medium", "large", "medium"],
      images: [[vid2_1, "100%","100%"], img2_2, img2_3],
      type: [false, true, true],
      title: [
        "LOBNA BEN AMMAR MAKEUP ARTIST",
        "ABOUT",
        "AZIZ SABRI FASHION DESIGNER",
      ],
      project_pages: ["/Lobna-Ben-Ammar", "/About", "/Aziz-Sabri"],
      title_id: ["", "", ""],
    },
    {
      sizes: ["medium", "large", "large"],
      images: [img3_1, [vid3_2, "100%","100%"], [vid3_3, "100%","100%"]],
      type: [true, false, false],
      title: ["COVER MTP", "KHOMSA D'OR 2019", "WBA WEDDING DRESS"],
      project_pages: ["/Cover-MTP", "/Khomsa-D'or-2019", "/WBA"],
      title_id: ["", "", ""],
    },
    {
      sizes: ["medium", "large", "small"],
      images: [[vid4_1, "100%","100%"], img4_2, img4_3],
      type: [false, true, true],
      title: ["CLICK.TN", "GET READY-TO-WEAR", "LOBNA BEN AMMAR MAKEUP ARTIST"],
      project_pages: ["/Click-TN", "/GET", "/Lobna-ben-Ammar-2"],
      title_id: ["", "", ""],
    },
    {
      sizes: ["large", "large", "medium"],
      images: [[vid5_1, "100%","100%"], img5_2, [vid5_3, "100%","100%"]],
      type: [false, true, false],
      title: ["SPOT TV", "LYOUNA SWIMSUIT", "OMAR WEDDING DRESS"],
      project_pages: ["/Spot-TV", "/Lyouna", "/Omar-Wedding-Dress"],
      title_id: ["", "", ""],
    },
    {
      sizes: ["small", "large", "medium"],
      images: [[vid6_1, "100%","100%"], [vid6_2, "100%","100%"], img6_3],
      type: [false, false, true],
      title: [
        "YOSRA MOURAD MAKE UP ARTIST",
        "NUTRITION TIP WITH DR.AMIRA ASKRI",
        "E-COMMERCE",
      ],
      project_pages: ["/Yosra-Mourad", "/Nutrition-Tip", "/E-Commerce"],
      title_id: ["", "", ""],
    },
    {
      sizes: ["medium", "large", "small"],
      images: [img7_1, [vid7_2, "100%","100%"], [vid7_3, "100%","100%"]],
      type: [true, false, false],
      title: ["E-COMMERCE", "LBK MAKEUP ARTIST", "JEWELRY SHOP"],
      project_pages: ["/E-Commerce-2", "/LBK", "/Jewelry-Shop"],
      title_id: ["", "", ""],
    },
    {
      sizes: ["medium", "small", "large"],
      images: [[vid8_1, "100%","100%"], img8_2, [vid8_3, "100%","100%"]],
      type: [false, true, false],
      title: ["COOKING SHOW", "COVER MTP", "IRI WORKSHOP"],
      project_pages: ["/Cooking-Show", "/Cover-MTP-2", "/IRI"],
      title_id: ["", "", ""],
    },
  ];

  const [items, setItems] = useState(data);

  let [mobileScreenSize, setMobileScreenSize] = useState(false);


  function reportWindowSize(){
    window.innerWidth < 600 ? setMobileScreenSize(true) : setMobileScreenSize(false);
  }

  window.onresize = reportWindowSize;
  
  const scroll_arr = (
    <div className="scroll-arrow" data-scroll-sticky>
      <h4>Scroll Down</h4>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          fill="#303C42"
          d="M12 18a.5.5 0 0 1-.346-.138l-11.5-11a.5.5 0 0 1 .692-.723L12 16.809l11.154-10.67a.5.5 0 0 1 .692.723l-11.5 11A.5.5 0 0 1 12 18z"
        />
        <linearGradient
          id="a"
          x1="1.021"
          x2="20.82"
          y1="4.199"
          y2="13.432"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#FFF" stop-opacity=".2" />
          <stop offset="1" stop-color="#FFF" stop-opacity="0" />
        </linearGradient>
        <path
          fill="url(#a)"
          d="M12 18a.5.5 0 0 1-.346-.138l-11.5-11a.5.5 0 0 1 .692-.723L12 16.809l11.154-10.67a.5.5 0 0 1 .692.723l-11.5 11A.5.5 0 0 1 12 18z"
        />
      </svg>
    </div>
  );

  const not_scroll_arr = <div className="not-scroll-arrow"></div>;

  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: document.querySelector(".App"),
      smooth: true,
      smartphone: {
        smooth: true,
      },
      tablet: {
        smooth: true,
      },
    });

    window.onload = () => {
      scroll.update();
    };
    window.onscroll = () => {
      scroll.update();
    };
  }, []);
  return (
    <div className="Cont">
      <div className="name" >
        <h1 className="title">Youssef</h1>
        <h1 className="art">Art Director</h1>
      </div>
      <div className="icons">
        <a href="https://www.youtube.com/channel/UCooSJYJuKNPdY2BnuNH3vQA">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 24 24"
          >
            <path d="M23,9.71a8.5,8.5,0,0,0-.91-4.13,2.92,2.92,0,0,0-1.72-1A78.36,78.36,0,0,0,12,4.27a78.45,78.45,0,0,0-8.34.3,2.87,2.87,0,0,0-1.46.74c-.9.83-1,2.25-1.1,3.45a48.29,48.29,0,0,0,0,6.48,9.55,9.55,0,0,0,.3,2,3.14,3.14,0,0,0,.71,1.36,2.86,2.86,0,0,0,1.49.78,45.18,45.18,0,0,0,6.5.33c3.5.05,6.57,0,10.2-.28a2.88,2.88,0,0,0,1.53-.78,2.49,2.49,0,0,0,.61-1,10.58,10.58,0,0,0,.52-3.4C23,13.69,23,10.31,23,9.71ZM9.74,14.85V8.66l5.92,3.11C14,12.69,11.81,13.73,9.74,14.85Z" />
          </svg>
        </a>
        <a href="https://www.linkedin.com/in/youssef-oueslati-926497106/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 24 24"
          >
            <path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z" />
          </svg>
        </a>
      </div>
      <div className="App" data-scroll-container>
        {items.map(({ sizes, images, type,title,project_pages,title_id }, index) => {
          return (
            <div className={"box "} id={index.toString()} data-scroll>
              <Card
                size={sizes[0]}
                speed={mobileScreenSize ? "0" : "5"}
                title={title[0]}
                image={images[0]}
                type={type[0]}
                project_page={project_pages[0]}
                title_id={title_id[0]}
                mobileScreenSize={mobileScreenSize}
              />
              <Card
                size={sizes[1]}
                speed={mobileScreenSize ? "0" : "1"}
                title={title[1]}
                image={images[1]}
                type={type[1]}
                project_page={project_pages[1]}
                title_id={title_id[1]}
                mobileScreenSize={mobileScreenSize}
              />
              <Card
                size={sizes[2]}
                speed={mobileScreenSize ? "0" : "2"}
                title={title[2]}
                image={images[2]}
                type={type[2]}
                project_page={project_pages[2]}
                title_id={title_id[2]}
                mobileScreenSize={mobileScreenSize}
              />
              {index == 0 ? scroll_arr : not_scroll_arr}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Landing;
