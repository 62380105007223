import { React } from "react";
import { Route } from "react-router-dom";
import Landing from "./Landing";
import Project_11 from "./Pages/Project_11";
import Project_12 from "./Pages/Project_12";
import Project_13 from "./Pages/Project_13";
import Project_21 from "./Pages/Project_21";
import Project_22 from "./Pages/Project_22";
import Project_23 from "./Pages/Project_23";
import Project_31 from "./Pages/Project_31";
import Project_32 from "./Pages/Project_32";
import Project_33 from "./Pages/Project_33";
import Project_41 from "./Pages/Project_41";
import Project_42 from "./Pages/Project_42";
import Project_43 from "./Pages/Project_43";
import Project_51 from "./Pages/Project_51";
import Project_52 from "./Pages/Project_52";
import Project_53 from "./Pages/Project_53";
import Project_61 from "./Pages/Project_61";
import Project_62 from "./Pages/Project_62";
import Project_63 from "./Pages/Project_63";
import Project_71 from "./Pages/Project_71";
import Project_72 from "./Pages/Project_72";
import Project_73 from "./Pages/Project_73";
import Project_81 from "./Pages/Project_81";
import Project_82 from "./Pages/Project_82";
import Project_83 from "./Pages/Project_83";

function App() {
  return (
    <div>
      
        <Route path="/" exact component={Landing}></Route>
        <Route path="/Najla-Garci" exact component={Project_11}></Route>
        <Route path="/MIT" exact component={Project_12}></Route>
        <Route path="/Evasion" exact component={Project_13}></Route>
        <Route path="/Lobna-Ben-Ammar" exact component={Project_21}></Route>
        <Route path="/About" exact component={Project_22}></Route>
        <Route path="/Aziz-Sabri" exact component={Project_23}></Route>
        <Route path="/Cover-MTP" exact component={Project_31}></Route>
        <Route path="/Khomsa-D'or-2019" exact component={Project_32}></Route>
        <Route path="/WBA" exact component={Project_33}></Route>
        <Route path="/Click-TN" exact component={Project_41}></Route>
        <Route path="/GET" exact component={Project_42}></Route>
        <Route path="/Lobna-ben-Ammar-2" exact component={Project_43}></Route>
        <Route path="/Spot-TV" exact component={Project_51}></Route>
        <Route path="/Lyouna" exact component={Project_52}></Route>
        <Route path="/Omar-Wedding-Dress" exact component={Project_53}></Route>
        <Route path="/Yosra-Mourad" exact component={Project_61}></Route>
        <Route path="/Nutrition-Tip" exact component={Project_62}></Route>
        <Route path="/E-Commerce" exact component={Project_63}></Route>
        <Route path="/E-Commerce-2" exact component={Project_71}></Route>
        <Route path="/LBK" exact component={Project_72}></Route>
        <Route path="/Jewelry-Shop" exact component={Project_73}></Route>
        <Route path="/Cooking-Show" exact component={Project_81}></Route>
        <Route path="/Cover-MTP-2" exact component={Project_82}></Route>
        <Route path="/IRI" exact component={Project_83}></Route>
      
    </div>
  );
}

export default App;
