import React, { useEffect } from "react";
import "./Project_61.css";
import ReactPlayer from "react-player";
import project_61_img1 from "../images/Inner Images/YOSRA MOURAD/IMG_0334 copie.jpg";
import project_61_img2 from "../images/Inner Images/YOSRA MOURAD/IMG_0358 copie.jpg";
import LocomotiveScroll from "locomotive-scroll";

function Project_61(props) {
  // document.body.style.overflow = "auto";
  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: document.querySelector(".Project_61-Project-Page-Cont"),
      smooth: true,
      mobile: {
        smooth: true,
      },
      tablet: {
        smooth: true,
      },
    });

    window.onload = () => {
      scroll.update();
    };
    window.onscroll = () => {
      scroll.update();
    };
  }, []);
  return (
    <div className="Project_61-Project-Page-Cont" data-scroll-container>
      <div className="hero-section" data-scroll>
        <div className="icons">
          <a href="https://www.youtube.com/channel/UCooSJYJuKNPdY2BnuNH3vQA">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M23,9.71a8.5,8.5,0,0,0-.91-4.13,2.92,2.92,0,0,0-1.72-1A78.36,78.36,0,0,0,12,4.27a78.45,78.45,0,0,0-8.34.3,2.87,2.87,0,0,0-1.46.74c-.9.83-1,2.25-1.1,3.45a48.29,48.29,0,0,0,0,6.48,9.55,9.55,0,0,0,.3,2,3.14,3.14,0,0,0,.71,1.36,2.86,2.86,0,0,0,1.49.78,45.18,45.18,0,0,0,6.5.33c3.5.05,6.57,0,10.2-.28a2.88,2.88,0,0,0,1.53-.78,2.49,2.49,0,0,0,.61-1,10.58,10.58,0,0,0,.52-3.4C23,13.69,23,10.31,23,9.71ZM9.74,14.85V8.66l5.92,3.11C14,12.69,11.81,13.73,9.74,14.85Z" />
            </svg>
          </a>
          <a href="https://www.linkedin.com/in/youssef-oueslati-926497106/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z" />
            </svg>
          </a>
        </div>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=vT4ukzmeS6E"
          volume={1}
          loop={true}
          playing={true}
          className="hero-video"
        />
      </div>
      <div className="images-cont" data-scroll>
        <div className="image-row">
          <div className="image">
            <img src={project_61_img1} alt="" />
          </div>
          <div className="no-image"></div>
        </div>
        <div className="image-row">
          <div className="no-image"></div>
          <div className="image">
            <img src={project_61_img2} alt="" />
          </div>
        </div>
      </div>
      <div className="credits-cont" data-scroll>
        <div className="cridits-title">
          <span>Credits</span>
        </div>
        <div className="description">
          <div className="description-row">
            <div className="description-title">
              <span>Makeup Artist</span>
            </div>
            <div className="description-content">
              <span>Yosra Mourad</span>
            </div>
          </div>
          <div className="description-row">
            <div className="description-title">
              <span>MODEL</span>
            </div>
            <div className="description-content">
              <span>Nadia</span>
            </div>
          </div>
          <div className="description-row">
            <div className="description-title">
              <span>PHOTOGRAPHER</span>
            </div>
            <div className="description-content">
              <span>Hedia Ben Arari</span>
            </div>
          </div>
          <div className="description-row">
            <div className="description-title">
              <span>ART DIRECTOR</span>
            </div>
            <div className="description-content">
              <span>Youssef Oueslati</span>
            </div>
          </div>
          <div className="description-row">
            <div className="description-title">
              <span>PRODUCTION</span>
            </div>
            <div className="description-content">
              <span>Studio Dellia</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project_61;
